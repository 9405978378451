.services{
    padding: 0 3rem 0 3rem;
    display: flex;
    height: 90vh;
    margin-bottom: 8rem;
    margin-top: 9rem;
    margin-bottom: 8rem;
}
.awesome{
    display: flex;
    flex-direction: column;
    position: relative;
}
.awesome>:nth-child(1){
    font-size: 3rem;
    font-weight: bold;
    color: var(--black);
}
.awesome>:nth-child(2){
    font-size: 3rem;
    font-weight: bold;
    color: var(--orange);
}
.awesome>:nth-child(3){
    color: var(--gray);
    margin-top: 10px;
}
.s-button{
    margin-top: 1rem;
    width: 8rem;
    height: 2rem;
}


.cards{
position: relative;
}
.cards>*{
    position: absolute;
}


.s-blur2{
    left: 14rem;
    top: 8rem;
}
.s-blur1{
    top: 13rem;
    left: -18rem;
}

@media screen and (max-width: 480px) {
    .services {
        margin-top: 0;
        flex-direction: column;
        gap: 5rem;
        height: 66rem;
        padding: 0;
      }
    
      .cards {
        display: flex;
        flex-direction: column;
        gap: 17rem;
      }
      .cards > * {
        position: static;
      }
}